;(function (angular, undefined) {
   'use strict'

   angular.module('app').factory('viewService', viewService)

   function viewService(
      $q,
      $filter,
      accountEntryData,
      rewardData,
      addressService,
      addressData,
      categoryData,
      emailData,
      orderData,
      tripData,
      stopData,
      orderService,
      dropData,
      personData,
      packagedProductTagService,
      productService,
      productData,
      paymentMethodData,
      util,
      tripService
   ) {
      function createView(data, prototype) {
         return angular.merge(Object.create(prototype || Object.prototype), data)
      }

      function toView(item) {
         return createView(item)
      }

      function toViews(items) {
         return items.map(toView)
      }

      function getObject(anything) {
         return (typeof anything === 'object' && anything) || {}
      }

      //================================================================================
      // AffiliateReferral Views
      //================================================================================

      function getAffiliateReferralViews(personId, bypassCache) {
         return personData.getAffiliateReferrals(personId, bypassCache).then(toViews)
      }

      //================================================================================
      // AccountEntry Prototype
      //================================================================================

      var _accountEntryPrototype = {
         get isCredit() {
            return this.amount >= 0
         },
         get isDebit() {
            return this.amount < 0
         },
         get debitOrderId() {
            if (this.notes && this.notes.startsWith('Order #')) {
               return this.notes.substring(this.notes.indexOf('#') + 1, this.notes.length)
            }
         },
      }

      //================================================================================
      // AccountEntry Views
      //================================================================================

      function toAccountEntryView(entry) {
         if (!entry) {
            return
         }
         return createView(entry, _accountEntryPrototype)
      }

      function toAccountEntryViews(entries) {
         return entries.map(toAccountEntryView)
      }

      function getAccountEntryViews(userId, start, limit, apiFilterParams, bypassCache) {
         return accountEntryData
            .getAccountEntries(userId, start, limit, apiFilterParams, bypassCache)
            .then(toAccountEntryViews)
      }

      //================================================================================
      // Address View Prototype
      //================================================================================

      var _addressViewPrototype = {
         get isInternational() {
            return addressService.isInternational(this)
         },
      }

      //================================================================================
      // Address Views
      //================================================================================

      function toAddressView(address) {
         if (!address) {
            return
         }
         return createView(address, _addressViewPrototype)
      }

      function toAddressViews(addresses) {
         return addresses.map(toAddressView)
      }

      function getUserAddressViews(userId) {
         return addressData.getUserAddresses(userId).then(toAddressViews)
      }

      //================================================================================
      // Category View Prototype
      //================================================================================

      var _categoryViewPrototype = {
         get shortNameOrName() {
            if (this['short-name'] !== undefined) {
               return this['short-name']
            }
            return this.name
         },
         get rootCategory() {
            if (this.parent === 0) {
               return this
            }
            return this.ancestorViews && this.ancestorViews[0]
         },
      }

      //================================================================================
      // Category Views
      //================================================================================

      function toCategoryView(category, options) {
         if (!category) {
            return
         }
         options = getObject(options)

         var categoryView = createView(category, _categoryViewPrototype)

         categoryView.path = generateCategoryPath(category)

         if (options.ancestorViews && category.ancestors) {
            categoryView.ancestorViews = categoryView.ancestors
               .map(function (ancestor, i) {
                  var ancestorView = createView(ancestor, _categoryViewPrototype)
                  ancestorView.path = generateCategoryPath(ancestor, category.ancestors.slice(i))
                  return ancestorView
               })
               .reverse()
         }

         return $q.resolve(categoryView)
      }

      function toCategoryViews(categories, options) {
         return $q.map(categories, function (category) {
            return toCategoryView(category, options)
         })
      }

      function getCategoryView(categoryId, options) {
         return categoryData.getCategory(categoryId, options).then(function (category) {
            return toCategoryView(category, options)
         })
      }

      function getCategoryViews(categoryIds, options) {
         return categoryData.getCategories(categoryIds).then(function (results) {
            return $q.map(results, function (result) {
               return toCategoryView(result.value, options)
            })
         })
      }

      function generateCategoryPath(category, ancestors) {
         ancestors = ancestors || category.ancestors || []
         var _path = []
         for (var i = ancestors.length - 1; i >= 0; i--) {
            _path.push(ancestors[i].slug)
         }
         if (!ancestors[0] || ancestors[0].id !== category.id) {
            _path.push(category.slug)
         }
         return _path.join('/')
      }

      //================================================================================
      // Drop View Prototype
      //================================================================================

      var _dropViewPrototype = {
         get hasHomeDelivery() {
            return this.hasHomeDeliveryDriver && this.isHomeDeliveryAllowed
         },
         isCoordinator: function (userId) {
            return this.coordinators.some(function (coordinatorId) {
               return coordinatorId === userId
            })
         },
         nextShoppableStopView: function (isSuperUser) {
            if (this.stopViews) {
               return this.stopViews.find(function (stopView) {
                  return stopView.canShop(isSuperUser)
               })
            }
         },
      }

      //================================================================================
      // Drop Views
      //================================================================================

      function toDropView(drop, options, bypassCache) {
         if (!drop) {
            return
         }
         options = getObject(options)

         var dropView = createView(drop, _dropViewPrototype)
         var maybeResolveStopViews

         if (options.stopViews) {
            options.stopViews = getObject(options.stopViews)
            maybeResolveStopViews = options.stopViews.resolve

            dropView.stopViewsLoading = true
            dropView.stopViewsPromise = getStopViewsByDropId(drop.id, options.stopViews, bypassCache)
               .then(util.compact)
               .then(function (stopViews) {
                  dropView.stopViews = util.sortByProperty('deliveryDate', stopViews)
                  if (stopViews.length) {
                     dropView.nextStopView = stopViews[0]
                  }
                  return dropView.stopViews
               })
               .finally(function () {
                  dropView.stopViewsLoading = false
               })
         }

         return $q.resolve(maybeResolveStopViews && dropView.stopViewsPromise).then(function () {
            return dropView
         })
      }

      function toDropViews(drops, options) {
         return $q.map(drops, function (drop) {
            return toDropView(drop, options)
         })
      }

      function getDropView(dropId, options, bypassCache) {
         return dropData.getDrop(dropId, bypassCache).then(function (drop) {
            return toDropView(drop, options, bypassCache)
         })
      }

      function getUserActiveDropViews(userId, options, bypassCache) {
         return dropData.getUserActiveDrops(userId, bypassCache).then(function (drops) {
            return toDropViews(drops, options)
         })
      }

      function getActiveDropMembershipActiveDropViews(userId, options, bypassCache) {
         options = options || true
         return getActiveDropMembershipViewsWithActiveDrops(
            userId,
            {
               dropView: options,
            },
            bypassCache
         ).then(function (dropMembershipViews) {
            return dropMembershipViews.map(function (dropMembership) {
               return dropMembership.dropView
            })
         })
      }

      function getCoordinatorDropViews(userId, options) {
         return getUserActiveDropViews(userId, options).then(function (dropViews) {
            return dropViews.filter(function (dropView) {
               return dropView.isCoordinator(userId)
            })
         })
      }

      //================================================================================
      // Drop Membership Views
      //================================================================================

      function toDropMembershipView(dropMembership, options, extension) {
         if (!dropMembership) {
            return
         }

         options = getObject(options)
         extension = getObject(extension)

         var dropMembershipView = createView(dropMembership)

         var maybeDropViewPromise

         // getDropView will be called only if the extension parameter does not already contain the dropView.
         if (options.dropView && !extension.dropView) {
            maybeDropViewPromise = getDropView(dropMembership.drop, options.dropView)
         }

         var viewPromises = [$q.resolve(maybeDropViewPromise)]

         return $q.allSettled(viewPromises).then(function (results) {
            angular.extend(
               dropMembershipView,
               {
                  dropView: results[0].value,
               },
               extension
            )
            return dropMembershipView
         })
      }

      function getActiveDropMembershipViews(userId, options, bypassCache) {
         var dropMembershipsPromise = dropData.getActiveDropMemberships(userId)
         // getUserActiveDropViews will get all user's active drops to which their membership is active.
         // This is done to reduce the need to make an API call per dropMembership to get the associated drop.
         var maybeDropViewsPromise
         if (options.dropView) {
            maybeDropViewsPromise = getUserActiveDropViews(userId, options.dropView, bypassCache)
         }
         return $q.all([dropMembershipsPromise, $q.resolve(maybeDropViewsPromise)]).then(function (results) {
            var dropMemberships = results[0]
            var dropViews = results[1]
            return $q.map(dropMemberships, function (dropMembership) {
               var extension = {}
               if (dropViews) {
                  var dropView = util.findById(dropViews, dropMembership.drop)
                  if (dropView) {
                     extension.dropView = dropView
                  }
               }
               return toDropMembershipView(dropMembership, options, extension)
            })
         })
      }

      // Returns user's active drop memberships where the drop is also active.
      // It is possible for a user to have an active membership to an inactive drop.
      // Such drops are inaccessible to the user (API returns 403), so corresponding
      // drop memberships are filtered out.
      function getActiveDropMembershipViewsWithActiveDrops(userId, options, bypassCache) {
         options = getObject(options)
         options.dropView = options.dropView || true
         return getActiveDropMembershipViews(userId, options, bypassCache).then(function (dropMemberships) {
            return dropMemberships.filter(function (dropMembership) {
               return dropMembership.dropView && dropMembership.dropView.active
            })
         })
      }

      //================================================================================
      // Email Views
      //================================================================================

      function toEmailView(email) {
         if (!email) {
            return
         }
         return angular.merge({}, email)
      }

      function toEmailViews(emails) {
         return emails.map(toEmailView)
      }

      function getUserEmailViews(userId) {
         return emailData.getUserEmails(userId).then(toEmailViews)
      }

      //================================================================================
      // Order View Prototype
      //================================================================================

      var _orderViewPrototype = {
         get cutoffDate() {
            return this.tripView && this.tripView.cutoffDate
         },
         get cutoffOrExtendedCutoffDate() {
            return this.stopView && this.stopView.cutoffOrExtendedCutoffDate
         },
         get pickDate() {
            return this.tripView && this.tripView.pickDate
         },
         get isEmpty() {
            if (this.lineViews) {
               return this.lineViews.length === 0
            }
         },
         get isStatusOpen() {
            return this.status === orderData.statuses.open
         },
         get isStatusPlaced() {
            return this.status === orderData.statuses.placed
         },
         get isStatusConfirmed() {
            return this.status === orderData.statuses.confirmed
         },
         get isStatusShipped() {
            return this.status === orderData.statuses.shipped
         },
         get hasShipped() {
            // Note that this is a date string (no time)
            return !!this.shipped
         },
         get isStatusDeliveredToDrop() {
            return this.status === orderData.statuses.deliveredToDrop
         },
         get hasBeenLoadedForD2h() {
            return (
               this.status === orderData.statuses.outForHomeDelivery ||
               this.status === orderData.statuses.deliveredToHome
            )
         },
         get isStatusOutForHomeDelivery() {
            return this.status === orderData.statuses.outForHomeDelivery
         },
         get hasBeenDeliveredToDrop() {
            // Note that this does not mean that the drop was necessarily the final destination.
            return (
               this.status === orderData.statuses.deliveredToDrop ||
               this.status === orderData.statuses.outForHomeDelivery ||
               this.status === orderData.statuses.deliveredToHome
            )
         },
         get hasBeenDeliveredToHome() {
            return !!this.homeDeliveryCompletedAt
         },
         get statusDisplay() {
            /* 
            
            IMPORTANT
            
            The `isStatus*` properties are exclusive. 

            Example: A Drop-to-Home order that has been delivered to a drop and is currently out for home delivery
            will have `isStatusDeliveredToDrop` as `false` and `isStatusOutForHomeDelivery` as `true` (even though it *has been* delivered to the drop).

            */

            if (this.isStatusOpen) {
               return 'Open'
            }
            if (this.isStatusPlaced) {
               if (this.canShop()) {
                  return 'Placed'
               }
               return 'Placed & Processing'
            }
            if (this.isStatusConfirmed) {
               if (this.stopView && this.stopView.deliveryDateType === 'finalized') {
                  return 'Delivery Scheduled'
               }
               return 'Confirmed'
            }
            if (this.isStatusShipped) {
               if (this.isParcelCarrier) {
                  // Note that unfortunately we don't yet have any concept of a parcel carrier order being delivered.
                  return 'Shipped Parcel Carrier'
               }
               if (this.stopView && (this.stopView.deliveryDateType === 'actual' || this.stopView.assumeDelivered)) {
                  // This handles drop orders that are "mistakenly stuck" with a status of `shipped` (those that have been
                  // delivered but did not get their statuses updated to `delivered-to-drop` for whatever reason).
                  return 'Delivered to Drop'
               }
               if (this.stopView && this.stopView.finalizedDeliveryFlag) {
                  return 'Shipping to Drop (truck delayed)'
               }
               return 'Shipping to Drop'
            }
            if (this.isStatusDeliveredToDrop) {
               return 'Delivered to Drop'
            }
            if (this.isStatusOutForHomeDelivery) {
               return 'Out for Home Delivery'
            }
            if (this.hasBeenDeliveredToHome) {
               return 'Delivered to Home'
            }

            // In case a potential state was missed, return a "prettified" version of the API value for the status.
            return this.status[0].toUpperCase() + this.status.slice(1).replaceAll('-', ' ')
         },
         get isStatusCancelled() {
            return this.status === orderData.statuses.cancelled
         },
         get isResolved() {
            return orderData.resolvedStatusStrings.includes(this.status)
         },
         get isDelivered() {
            if (!this.hasShipped) {
               return false
            }
            if (this.isD2h) {
               return this.hasBeenDeliveredToHome
            }
            if (this.isDropAndNotD2h) {
               return this.isStatusDeliveredToDrop
            }
            if (this.stopView) {
               return this.stopView.deliveryDate < new Date()
            }
         },
         get dateOfDeliveryToFinalDestination() {
            if (this.isD2h) {
               return this.homeDeliveryCompletedAt
            }
            if (this.isDropAndNotD2h) {
               return this.stopView.deliveryDate
            }
            // TODO: Ideally we'd be able to get the parcel carrier delivery date here.
         },
         get isLocal() {
            return !this.customer
         },
         get isApproachingCutoff() {
            return this.tripView && this.tripView.isApproachingCutoff
         },
         get isPastCutoff() {
            return this.tripView && this.tripView.isPastCutoff
         },
         get isExtendedCutoff() {
            return this.stopView && this.tripView && this.stopView.isExtendedCutoff
         },
         get isPastFinalCutoff() {
            return this.stopView && this.stopView.isPastFinalCutoff
         },
         get isDrop() {
            // Note that this includes home delivery orders
            return !!this.drop
         },
         get isDropAndNotD2h() {
            return this.isDrop && !this.isD2h
         },
         get isD2h() {
            return !!this.dropToHomeDeliveryAddress
         },
         get isParcelCarrier() {
            return !!this.parcelCarrierDeliveryAddress
         },
         get shippingMethodDestinationLabel() {
            if (this.isD2h) {
               return 'Drop-to-Home via'
            } else if (this.isParcelCarrier) {
               return 'Delivery via'
            } else if (this.dropView) {
               return 'Pickup at'
            }
         },
         get destinationName() {
            if (this.dropView) {
               return this.dropView.name
            } else if (this.isParcelCarrier) {
               return 'Parcel Carrier'
            }
         },
         get destinationNameOrDropId() {
            if (this.destinationName) {
               return this.destinationName
            } else if (this.drop) {
               return this.drop
            }
         },
         get isLargeParcelCarrier() {
            if (!this.hasParcelCarrierServices) {
               return false
            }
            return (
               orderService.isLargeShippingService(this['parcel-carrier-services'].all) ||
               orderService.isLargeShippingService(this['parcel-carrier-services'].roomTemp) ||
               orderService.isLargeShippingService(this['parcel-carrier-services'].chilled)
            )
         },
         get shippingTypeString() {
            if (this.isParcelCarrier) {
               return 'Parcel Carrier'
            } else if (this.isDrop) {
               return 'Drop'
            }
         },
         get hasParcelCarrierServices() {
            return !!this['parcel-carrier-services']
         },
         get isShippingConfigured() {
            var _this = this
            if (!_this.isStatusOpen || _this.isDrop) {
               return true
            }

            if (_this.isParcelCarrier && _this.hasParcelCarrierServices) {
               // We know shipping is fully configured if the perishable shipping warning is accepted.
               if (_this['perishable-shipping-warning-accepted']) {
                  return true
               }

               if (!this.parcelCarrierEstimates) {
                  // Without the estimates, we don't know if shipping is fully configured because
                  // we don't know if any of the order's parcel carrier services have
                  // requires-accepted-perishable-shipping-warning
                  return
               }

               var parcelCarrierEstimates = Object.keys(_this['parcel-carrier-services']).map(function (climate) {
                  var service = _this['parcel-carrier-services'][climate]
                  return _this.findParcelCarrierEstimate(climate, service)
               })

               // Shipping is fully configured if we have estimates for the order's parcel carrier services
               // and all of the estimates do not have requires-accepted-perishable-shipping-warning
               return parcelCarrierEstimates.every(function (estimate) {
                  return estimate && !estimate['requires-accepted-perishable-shipping-warning']
               })
            }

            return false
         },
         get destinationAddress() {
            if (this.isD2h) {
               return this.dropToHomeDeliveryAddress
            } else if (this.isParcelCarrier) {
               return this.parcelCarrierDeliveryAddress
            } else if (this.dropView) {
               return this.dropView.address || {name: this.dropView.name}
            }
         },
         get hasDestination() {
            return orderService.hasDestination(this)
         },
         get containsBargainBin() {
            return (
               this.lineViews &&
               this.lineViews.some(function (lineView) {
                  return lineView['packaged-product'].startsWith('BB')
               })
            )
         },
         get containsOutOfStockOnPickDate() {
            return (
               this.lineViews &&
               this.lineViews.some(function (lineView) {
                  return lineView.packView && lineView.packView.isOutOfStockOnPickDate
               })
            )
         },
         get lineViewsWithStockConflict() {
            return (
               this.lineViews &&
               this.lineViews.filter(function (lineView) {
                  return lineView.stockConflict
               })
            )
         },
         get anyStockConflicts() {
            return this.lineViewsWithStockConflict && this.lineViewsWithStockConflict.length
         },
         get latestNextPurchaseArrivalDate() {
            var latestDate
            this.lineViewsWithStockConflict.forEach(function (lineView) {
               if (
                  lineView.packView.nextPurchaseArrivalDate &&
                  (!latestDate || lineView.packView.nextPurchaseArrivalDate > latestDate)
               ) {
                  latestDate = lineView.packView.nextPurchaseArrivalDate
               }
            })
            return latestDate
         },
         get lineViewsByTemperatureType() {
            var _this = this
            if (_this.productsResolved && _this._lineViewsByTemperatureType === undefined) {
               var lineViewsByTemperatureType = {}
               _this.lineViews.forEach(function (line) {
                  if (!line.productView) {
                     return
                  }

                  var lineGroup = lineViewsByTemperatureType[line.productView.temperatureType.key]
                  if (!lineGroup) {
                     lineGroup = {
                        lineViews: [],
                        countOrdered: 0,
                        countShipped: 0,
                        weight: 0,
                        volume: 0,
                        price: 0,
                     }
                     lineViewsByTemperatureType[line.productView.temperatureType.key] = lineGroup
                  }
                  lineGroup.lineViews.push(line)
                  lineGroup.countOrdered += line['quantity-ordered']
                  lineGroup.countShipped += line['quantity-shipped']
                  lineGroup.weight += line.weight
                  lineGroup.volume += line.volume
                  lineGroup.price += line.price
               })
               _this._lineViewsByTemperatureType = lineViewsByTemperatureType
            }
            return _this._lineViewsByTemperatureType
         },
         get paymentMethodId() {
            return this['checkout-payment'] && this['checkout-payment']['payment-method']
         },
         get totalPromoCodeDiscounts() {
            if (!this.promoCodeViews) {
               return 0
            }
            return this.promoCodeViews.reduce(function (total, promoCode) {
               if (promoCode.discountAmount) {
                  total += promoCode.discountAmount
               }
               return total
            }, 0)
         },
         get codesFromPromoCodeViews() {
            return (
               this.promoCodeViews &&
               this.promoCodeViews.map(function (entry) {
                  return entry.code
               })
            )
         },
         get maybeAllowLateSwitchingToD2h() {
            return this.isDropAndNotD2h && !this.isStatusDeliveredToDrop && this.stopView && !this.stopView.canShop()
         },
         get areD2hEstimatesMaybeRelevant() {
            return this.isStatusOpen || (!this.isParcelCarrier && !this.isStatusDeliveredToDrop)
         },
         findParcelCarrierEstimate: function (climate, service) {
            return (
               this.parcelCarrierEstimates &&
               this.parcelCarrierEstimates.find(function (estimate) {
                  return estimate.climate === climate && estimate.service === service
               })
            )
         },
         containsProductCode: function (productCode) {
            return (
               this.lineViews &&
               this.lineViews.some(function (lineView) {
                  return lineView['packaged-product'] === productCode
               })
            )
         },
         containsTemperatureType: function (temperatureTypeKey) {
            return !!this.lineViewsByTemperatureType[temperatureTypeKey]
         },
         canShop: function (isSuperUser) {
            if (this.isParcelCarrier || !this.stopView) {
               return this.isStatusOpen
            }
            return this.stopView.canShop(isSuperUser)
         },
         calculateTotals: function () {
            var totals = orderService.calculateTotals(this, this.lineViews)
            return angular.extend(this, totals)
         },
         getQuantity: function (productCode) {
            if (productCode in this._quantities) {
               return this._quantities[productCode]
            }
            var quantity
            if (this.lineViews) {
               quantity = this.lineViews.reduce(function (sum, orderLine) {
                  if (orderLine['packaged-product'] === productCode) {
                     return sum + orderLine['quantity-ordered']
                  }
                  return sum
               }, 0)
            }
            this._quantities[productCode] = quantity
            return quantity
         },
         cannotAddProduct: function (product) {
            if (!product) {
               return true
            }
            if (
               product.unshippableRegions &&
               product.unshippableRegions.length &&
               this.destinationAddress &&
               this.destinationAddress.region
            ) {
               return product.unshippableRegions.includes(this.destinationAddress.region)
            }
            if (this.isParcelCarrier && product.isShippableUps === false) {
               return true
            }
            if (this.isDrop) {
               return this.willProductExpire(product)
            }
            return false
         },
         willProductExpire: function (product) {
            if (product && this.tripView && this.stopView) {
               var deliveryDate = this.stopView.deliveryDate
               var pickDate = this.tripView.pickDate
               return orderService.willProductExpire(product.maxStorageDays, deliveryDate, pickDate)
            }
         },
         getProductWarning: function (product, shortOrLong) {
            var warnings = {
               unshippableRegion: {
                  short: 'Cannot ship to region',
                  long: 'This item cannot be delivered to your region due to state regulations.',
               },
               dropOnly: {
                  short: 'Drop deliveries only',
                  long:
                     "This item can only be delivered to a drop. If you'd like to order this item, change your shipping method.",
               },
               parcelOnly: {
                  short: 'Likely to expire in transit',
                  long:
                     "This product is likely to expire before it gets to your drop. If you'd like to order this item, change your shipping method.",
               },
            }

            if (
               product.unshippableRegions &&
               product.unshippableRegions.length &&
               this.destinationAddress &&
               this.destinationAddress.region &&
               product.unshippableRegions.includes(this.destinationAddress.region)
            ) {
               return warnings.unshippableRegion[shortOrLong]
            } else if (this.isParcelCarrier && !product.isShippableUps) {
               return warnings.dropOnly[shortOrLong]
            } else if (this.willProductExpire(product)) {
               return warnings.parcelOnly[shortOrLong]
            }
            return ''
         },
      }

      //================================================================================
      // Order Views
      //================================================================================

      function toOrderView(order, options, bypassCache) {
         if (!order) {
            return
         }

         options = getObject(options)

         var orderView = createView(order, _orderViewPrototype)
         orderView._quantities = {}

         orderView.priceSettings = options.priceSettings

         var maybeOrderLinesPromise
         var maybeDropViewPromise
         var maybeTripViewPromise
         var maybeStopViewPromise
         var maybeOrderFeesPromise
         var maybePaymentMethodViewPromise
         var maybeParcelCarrierEstimates
         var maybeRewardsUsedPromise
         var maybePromoCodesPromise
         var maybeD2hEstimatesPromise

         if (options.lineViews) {
            maybeOrderLinesPromise = getOrderLineViews(orderView, options.lineViews, bypassCache)
         }
         if (options.dropView && order.drop) {
            maybeDropViewPromise = getDropView(order.drop, options.dropView, bypassCache)
         }
         if (options.tripView && order.trip) {
            maybeTripViewPromise = getTripView(order.trip, bypassCache)
         }
         if (options.stopView && order.trip && order.drop) {
            maybeStopViewPromise = getStopView(order.drop, order.trip, options.stopView, bypassCache)
         }
         if (options.fees) {
            maybeOrderFeesPromise = orderData.getOrderFees(order.id, bypassCache)
         }
         if (options.paymentMethodView && orderView.paymentMethodId) {
            maybePaymentMethodViewPromise = paymentMethodData.getPaymentMethod(
               order.customer,
               orderView.paymentMethodId,
               bypassCache
            )
         }
         if (options.parcelCarrierEstimates && orderView.isParcelCarrier && orderView.isStatusOpen) {
            maybeParcelCarrierEstimates = orderData.getParcelCarrierEstimates(
               order.id,
               order.parcelCarrierDeliveryAddress,
               bypassCache
            )
         }
         if (options.rewardsUsed && orderView.hasShipped && order['rewards-allocation']) {
            maybeRewardsUsedPromise = rewardData.getRewardsUsedOnOrder(order.customer, order.id)
         }

         if (options.promoCodeViews && order.orderPromoCodeIds) {
            maybePromoCodesPromise = $q.all(
               order.orderPromoCodeIds.map(function (promoCodeId) {
                  return orderData.getPromoCodeById(promoCodeId, order.id, bypassCache)
               })
            )
         }

         if (options.d2hEstimates && orderView.areD2hEstimatesMaybeRelevant) {
            maybeD2hEstimatesPromise = getUserAddressViews(order.customerId).then(function (addressViews) {
               var fetchD2hDeliveryFeeEstimatesPromises = addressViews.map(function (address) {
                  return orderData.getSingleD2hQualifyingEstimate(order.id, address, order.drop, order.trip, true)
               })

               return $q.all(fetchD2hDeliveryFeeEstimatesPromises).then(function (estimateArraysGroupedByAddress) {
                  return estimateArraysGroupedByAddress.flat()
               })
            })
         }

         var viewPromises = [
            $q.resolve(maybeOrderLinesPromise),
            $q.resolve(maybeDropViewPromise),
            $q.resolve(maybeTripViewPromise),
            $q.resolve(maybeStopViewPromise),
            $q.resolve(maybeOrderFeesPromise),
            $q.resolve(maybePaymentMethodViewPromise),
            $q.resolve(maybeParcelCarrierEstimates),
            $q.resolve(maybeRewardsUsedPromise),
            $q.resolve(maybePromoCodesPromise),
            $q.resolve(maybeD2hEstimatesPromise),
         ]

         return $q.allSettled(viewPromises).then(function (results) {
            return assembleOrderView(orderView, results, options)
         })
      }

      function assembleOrderView(orderView, results, options) {
         angular.extend(orderView, {
            lineViews: results[0].value,
            dropView: results[1].value,
            tripView: results[2].value,
            stopView: results[3].value,
            fees: results[4].value,
            parcelCarrierEstimates:
               results[6].value &&
               results[6].value.sort(function (a, b) {
                  return (a.cost || 0) - (b.cost || 0)
               }),
            rewardsUsed: results[7].value,
            promoCodeViews: results[8].value,
            d2hEstimates: results[9].value,
            errors: {},
         })

         if (orderView.stopView) {
            orderView.stopView.tripView = orderView.tripView
         }

         // Add rejected promises' reasons to errors
         if (results[6].state === 'rejected') {
            orderView.errors.parcelCarrierEstimates = results[6].reason
         }

         if (options.paymentMethodView && orderView['checkout-payment']) {
            orderView['checkout-payment'].paymentMethodView = results[5].value
         }

         if (orderView.fees) {
            orderView.maybeFeesOtherThanParcelCarrier = orderView.fees.filter(function (item) {
               return item.type !== 'parcel-carrier'
            })
            orderView.maybeParcelCarrierFees = orderView.fees.filter(function (item) {
               return item.type === 'parcel-carrier'
            })
         }

         // ================================================================
         // TODO: [CLIMATE_NAMING_REFACTOR] Remove this once the API is updated
         if (orderView && orderView.computed) {
            util.renameProperty(orderView.computed.cases, 'refrigeratedAndRoomTemp', 'chilledAndRoomTemp')
            util.renameProperty(orderView.computed.quantityOrdered, 'refrigerated', 'chilled')
            util.renameProperty(orderView.computed.quantityShipped, 'refrigerated', 'chilled')
            util.renameProperty(orderView.computed.volumeOrdered, 'refrigerated', 'chilled')
            util.renameProperty(orderView.computed.volumeShipped, 'refrigerated', 'chilled')
            util.renameProperty(orderView.computed.weightOrdered, 'refrigerated', 'chilled')
            util.renameProperty(orderView.computed.weightShipped, 'refrigerated', 'chilled')
         }
         if (orderView && orderView['parcel-carrier-services']) {
            util.renameProperty(orderView['parcel-carrier-services'], 'dry', 'roomTemp')
            util.renameProperty(orderView['parcel-carrier-services'], 'chilled/frozen', 'chilled')
         }
         if (orderView.parcelCarrierEstimates && orderView.parcelCarrierEstimates.length) {
            orderView.parcelCarrierEstimates = orderView.parcelCarrierEstimates.map(function (parcelEstimate) {
               if (parcelEstimate.climate === 'chilled/frozen') {
                  parcelEstimate.climate = 'chilled'
               } else if (parcelEstimate.climate === 'dry') {
                  parcelEstimate.climate = 'roomTemp'
               }
               return parcelEstimate
            })
         }
         // END TODO
         // ================================================================

         // Check stock on pick date if necessary
         if (
            !orderView.hasShipped &&
            orderView.productsPromise &&
            options.lineViews.productViews.checkStockAndSubstitutionsOnPickDate
         ) {
            $q.all([orderView.refreshStockDataPromise, orderView.productsPromise]).then(function () {
               orderView.lineViews.forEach(function (lineView) {
                  if (!lineView.packView) {
                     return
                  }

                  lineView.packView.isOutOfStockOnPickDate = lineView.packView.isOutOfStockOn(orderView.pickDate)
                  if (lineView.packView.isOutOfStockOnPickDate) {
                     lineView.productView
                        .hasActiveSubstitution({inStockOn: orderView.pickDate})
                        .then(function (hasSubs) {
                           lineView.productView.hasSubstitutionOnPickDate = hasSubs
                        })
                  }
               })
            })
         }

         // Calculate order totals
         orderView.calculateTotals()

         return orderView
      }

      function getOrderLinePackagedProducts(orderLineViews) {
         var codes = orderLineViews.map(function (lineView) {
            return lineView['packaged-product']
         })
         return productData.getPackagedProductsByCode(codes)
      }

      function mapProductViewsToLineViews(orderView, lineViews, productViews) {
         lineViews.forEach(function (lineView, i) {
            lineView.productView = productViews[i]
            if (!orderView.isLocal || !lineView.packView || !lineView.packView.userPrice) {
               return
            }

            // For local orderlines, certain properties need to be set from the product
            lineView.price = lineView.packView.userPrice.dollarsMedian * lineView.quantity
            lineView.volume = lineView.packView.volume * lineView.quantity
            lineView.weight = lineView.packView.weight.shipping * lineView.quantity
            lineView.rewards =
               util.pennyRound(
                  (lineView.packView.userPrice.dollarsMedian * orderView.priceSettings.rewardsRate) / 100
               ) * lineView.quantity
         })
      }

      function toOrderViews(orders, options, bypassCache) {
         options = getObject(options)

         var maybeStopViewsPromise
         if (options.stopView) {
            var dropIdTripIds = orders.map(function (order) {
               return {
                  dropId: order.drop,
                  tripId: order.trip,
               }
            })
            maybeStopViewsPromise = getStopViewsByDropIdTripIds(dropIdTripIds, options.stopView, bypassCache)
         }

         var orderViewOptions = angular.extend({}, options, {
            stopView: undefined,
         })
         var orderViewsPromise = $q.map(orders, function (order) {
            return toOrderView(order, orderViewOptions, bypassCache)
         })

         return $q.all([$q.resolve(maybeStopViewsPromise), orderViewsPromise]).then(function (results) {
            var stopViews = results[0]
            var orderViews = results[1]
            if (stopViews) {
               orderViews.forEach(function (orderView, i) {
                  orderView.stopView = stopViews[i]
                  if (orderView.stopView) {
                     orderView.stopView.tripView = orderView.tripView
                  }
               })
            }
            return orderViews
         })
      }

      function getOrderView(orderId, options) {
         return orderData.getOrder(orderId).then(function (order) {
            return toOrderView(order, options)
         })
      }

      function getUnresolvedOrderViews(userId, options, bypassCache) {
         return orderData.getUnresolvedOrders(userId, bypassCache).then(function (orders) {
            return toOrderViews(orders, options, bypassCache)
         })
      }

      function getResolvedOrderViews(userId, start, limit, options) {
         return orderData.getResolvedOrders(userId, start, limit).then(function (orders) {
            return toOrderViews(orders, options)
         })
      }

      function getOrderViewsByDropTrip(dropId, tripId, options, bypassCache) {
         return orderData.getOrdersByDropTrip(dropId, tripId, bypassCache).then(function (orders) {
            return toOrderViews(orders, options, bypassCache)
         })
      }

      //================================================================================
      // OrderLine View Prototype
      //================================================================================

      var _orderLineViewPrototype = {
         get quantity() {
            if (this['quantity-shipped'] !== undefined) {
               return this['quantity-shipped']
            }
            return this['quantity-ordered']
         },
         get pricePerItem() {
            return util.pennyRound(this.price / this.quantity)
         },
         get stockConflict() {
            if (this.packView) {
               return this['quantity-ordered'] > this.packView.stock
            }
         },
         get packView() {
            return this.productView && this.productView.selectedPackaging
         },
      }

      //================================================================================
      // OrderLine Views
      //================================================================================

      function toOrderLineView(orderLine) {
         if (!orderLine) {
            return
         }

         return createView(orderLine, _orderLineViewPrototype)
      }

      function toOrderLineViews(orderView, orderLines, options) {
         var lineViews = orderLines.map(toOrderLineView)

         options = getObject(options)

         var maybeResolveProducts
         if (options.productViews) {
            orderView.productsPromise = getOrderLineProductViews(orderView, lineViews, options.productViews)

            // Local order lines don't store the price, so we need to wait for the
            // products to resolve in order to get the prices
            if (orderView.isLocal || options.productViews.resolve) {
               maybeResolveProducts = orderView.productsPromise
            }
         }

         return $q.resolve(maybeResolveProducts).then(function () {
            return lineViews
         })
      }

      function getOrderLineProductViews(orderView, lineViews, options) {
         options = getObject(options)

         options.priceSettings = options.priceSettings || orderView.priceSettings

         var productsPromise

         if (orderView.isLocal) {
            var productCodes = lineViews.map(function (orderLine) {
               return orderLine['packaged-product']
            })

            productsPromise = getProductViewsByCode(productCodes, options)
         } else {
            var productIdsAndCodes = lineViews.map(function (orderLine) {
               return {id: orderLine.product, code: orderLine['packaged-product']}
            })

            productsPromise = getProductViews(productIdsAndCodes, options)
         }

         productsPromise = productsPromise.then(function (productViews) {
            mapProductViewsToLineViews(orderView, lineViews, productViews)
            orderView.productsResolved = true
            return productViews
         })

         // non-blocking update to packaging data, if necessary
         if (options.refreshStockData && !orderView.hasShipped) {
            orderView.refreshStockDataPromise = $q
               .all([getOrderLinePackagedProducts(lineViews), productsPromise])
               .then(function (results) {
                  lineViews.forEach(function (lineView) {
                     if (lineView.packView) {
                        lineView.packView.updateStockData(
                           results[0].packagedProductsByCode[lineView['packaged-product']],
                           options.priceSettings
                        )
                     }
                  })
               })
         }

         return productsPromise
      }

      function getOrderLineViews(orderView, options, bypassCache) {
         return orderData.getOrderLines(orderView.id, bypassCache).then(function (orderLines) {
            return toOrderLineViews(orderView, orderLines, options)
         })
      }

      //================================================================================
      // PaymentMethod Views
      //================================================================================

      function getActivePaymentMethodViews(userId, bypassCache) {
         return paymentMethodData.getActivePaymentMethods(userId, bypassCache).then(toViews)
      }

      //================================================================================
      // Person View Prototype
      //================================================================================

      var _personViewPrototype = {
         get initials() {
            if (this.name) {
               var nameArray = this.name.split(' ')
               if (nameArray.length > 1) {
                  return nameArray[0][0] + nameArray[nameArray.length - 1][0]
               } else {
                  return nameArray[0][0]
               }
            }
         },
         addAffiliateIdToUrl: function (url) {
            var param = 'a_aid=' + this['affiliate-code']
            return util.addParamToUrl(param, url)
         },
      }

      //================================================================================
      // Person Views
      //================================================================================

      function toPersonView(person, options) {
         if (!person) {
            return
         }
         var personView = createView(person, _personViewPrototype)

         if (!options) {
            return personView
         }

         if (options.orderedPackagedProducts) {
            personView.orderedPackagedProductsPromise = personData
               .getOrderedPackagedProducts(person.id)
               .then(function (orderedPackagedProducts) {
                  personView.orderedPackagedProducts = orderedPackagedProducts
                  return orderedPackagedProducts
               })
         }

         return personView
      }

      function getPersonView(personId, options) {
         return personData.getPerson(personId).then(function (person) {
            return toPersonView(person, options)
         })
      }

      function getPersonViews(personIds, options) {
         return $q.map(personIds, function (personId) {
            return getPersonView(personId, options)
         })
      }

      //================================================================================
      // Package View Prototype
      //================================================================================

      var _packageViewPrototype = {
         get inactiveTag() {
            return (
               this.tags.find(function (tag) {
                  return tag === 'discontinued'
               }) ||
               this.tags.find(function (tag) {
                  return tag === 'out-long-term'
               }) ||
               this.tags.find(function (tag) {
                  return tag === 'out-for-season'
               }) ||
               this.tags.find(function (tag) {
                  return tag === 'new-not-on-sale'
               }) ||
               (this.isNotForSale && 'not-for-sale')
            )
         },
         get isDiscontinued() {
            return this.inactiveTag === 'discontinued'
         },
         get isOutLongTerm() {
            return this.inactiveTag === 'out-long-term'
         },
         get isOutForSeason() {
            return this.inactiveTag === 'out-for-season'
         },
         get isNewNotOnSale() {
            return this.inactiveTag === 'new-not-on-sale'
         },
         get isInHouse() {
            return this.tags.includes('in-house')
         },
         get isNotForSale() {
            return !this.userPrice
         },
         get isInactive() {
            return !!this.inactiveTag
         },
         get isOutOfStock() {
            return !this.stock || this.isInactive
         },
         get isBargainBin() {
            return this.tags.includes('bargain-bin')
         },
         get isDistributor() {
            return /^D[A-Z]{2}\d{3,}$/i.test(this.code)
         },
         get isOverstock() {
            return this.tags.includes('overstock')
         },
         get isClearance() {
            return this.isBargainBin || this.isOverstock
         },
         get inactiveLabel() {
            var _this = this
            if (!_this.labels || !_this.isInactive) {
               return
            }
            return _this.labels.find(function (label) {
               return label.slug === _this.inactiveTag
            })
         },
         get nextPurchaseArrivalPassed() {
            if (this.nextPurchaseArrivalDate) {
               var today = new Date()
               today.setHours(0, 0, 0, 0)
               return this.nextPurchaseArrivalDate < today
            }
         },
         get hasPurchaseArrivalLateWindowPassed() {
            if (this.nextPurchaseArrivalDate) {
               var today = new Date()
               today.setHours(0, 0, 0, 0)
               var lateWindowInDays = 7
               return this.nextPurchaseArrivalDate < new Date(today.setDate(today.getDate() - lateWindowInDays))
            }
         },
         stockStatusOn: function (pickDate) {
            var statuses = [
               {
                  status: 'Out of Stock',
               },
               {
                  status: 'Arriving Soon',
                  message: 'Though we expect more of this item soon, it is unlikely to arrive before your order ships.',
               },
               {
                  status: 'Arriving Soon',
                  message: 'Though currently out of stock, this item should be available by the time your order ships.',
               },
               {
                  status: 'In Stock',
               },
            ]

            var isOutNow = this.isOutOfStock
            var isOnPurchase = this.nextPurchaseArrivalDate && !this.hasPurchaseArrivalLateWindowPassed
            var isOutOnPickDate = this.isOutOfStockOn(pickDate)

            var status
            if (isOutNow) {
               if (!isOnPurchase) {
                  status = statuses[0]
               } else if (isOutOnPickDate) {
                  status = statuses[1]
               } else {
                  status = statuses[2]
               }
            } else {
               status = statuses[3]
            }
            return status
         },
         isOutOfStockOn: function (date) {
            return (
               this.isOutOfStock &&
               (!this.nextPurchaseArrivalDate ||
                  date === undefined ||
                  this.hasPurchaseArrivalLateWindowPassed ||
                  date < this.nextPurchaseArrivalDate)
            )
         },
         updateStockData: function (pack, priceSettings) {
            if (!pack) {
               return
            }
            this.stock = pack.stock
            this.tags = pack.tags
            this.labels = packagedProductTagService.getLabels(this, priceSettings.priceLevel)
            this['quantity-on-next-purchase'] = pack['quantity-on-next-purchase']
            this['next-purchase-arrival'] = pack['next-purchase-arrival']
            if (pack['next-purchase-arrival']) {
               this.nextPurchaseArrivalDate = new Date(pack['next-purchase-arrival'])
            } else {
               this.nextPurchaseArrivalDate = undefined
            }
         },
         refreshStockData: function (priceSettings) {
            var _this = this
            return productData.getPackagedProductsByCode([_this.code]).then(function (result) {
               return _this.updateStockData(result.packagedProductsByCode[_this.code], priceSettings)
            })
         },
         quantityLeftWarnThreshold: 20,
      }

      //================================================================================
      // Package Views
      //================================================================================

      var toPackageView = function (pack, options) {
         options = getObject(options)

         var packageView = createView(pack, _packageViewPrototype)
         var userPrice = packageView.price[options.priceSettings.priceLevel]

         if (userPrice) {
            packageView.userPrice = userPrice

            // Set median dollar price (for variable weight products)
            userPrice.dollarsMedian = util.pennyRound(
               userPrice.dollars * (userPrice['per-pound'] ? packageView.weight.net : 1)
            )

            // Set rewards rate
            if (packageView.rewardsEnabled) {
               userPrice.rewardsRate = Math.max(userPrice['rewards-rate'] || 0, options.priceSettings.rewardsRate)
               userPrice.rewardsMedian = util.pennyRound((userPrice.rewardsRate * userPrice.dollarsMedian) / 100)
            }

            // Calculate discount in dollars and percent
            if (userPrice.discount) {
               var discountValue = parseFloat(userPrice.discount.replace(/[$%]/gi, ''))
               if (userPrice.discount.includes('%')) {
                  userPrice.discountPercent = discountValue
                  userPrice.discountDollars = (userPrice.dollarsMedian * discountValue) / (100 - discountValue)
                  userPrice.dollarsMedianBeforeDiscount = userPrice.dollarsMedian + userPrice.discountDollars
               } else {
                  userPrice.discountDollars = discountValue
                  userPrice.dollarsMedianBeforeDiscount = userPrice.dollarsMedian + userPrice.discountDollars
                  userPrice.discountPercent = 100 * (discountValue / userPrice.dollarsMedianBeforeDiscount)
               }
            }
         }

         // Set next purchase arrival date
         if (packageView['next-purchase-arrival']) {
            packageView.nextPurchaseArrivalDate = new Date(packageView['next-purchase-arrival'])
         }
         return packageView
      }

      //================================================================================
      // Product View Prototype
      //================================================================================

      var _productViewPrototype = {
         get path() {
            var path = this.slug
            if (this.categoryView) {
               path = this.categoryView.path + '/' + path
            }
            return path
         },
         get temperatureType() {
            return productService.getTemperatureType(this.storageClimate)
         },
         get filtered() {
            return (
               this.filteredByTags ||
               this.filteredByInStock ||
               this.filteredByOutOfStock ||
               this.filteredByBonusRewards ||
               this.filteredByClearance ||
               this.filteredByCodes
            )
         },
         get primaryCategoryId() {
            return this.selectedPackagingOrDefault['primary-category']
         },
         get defaultPackaging() {
            if (!this.packaging.length) {
               return
            }
            // default packaging is the first non-bargain bin packaging
            return (
               this.packaging.find(function (pack) {
                  return !pack.isBargainBin
               }) || this.packaging[0]
            )
         },
         get selectedPackagingOrDefault() {
            return this.selectedPackaging || this.defaultPackaging
         },
         get filteredPackaging() {
            if (!this.filtered) {
               return this.packaging
            }
            return this.packaging.filter(function (pack) {
               return pack.filterMatch
            })
         },
         get hasActivePackaging() {
            return this.packaging.some(function (pack) {
               return !pack.isInactive
            })
         },
         get hasActiveFilteredPackaging() {
            return this.filteredPackaging.some(function (pack) {
               return !pack.isInactive
            })
         },
         get substitutionStaffPickProductIds() {
            var _this = this
            if (!_this.substitutions) {
               return []
            }
            return _this.substitutions.staffPicks
         },
         get images() {
            var images = []
            this.packaging.forEach(function (pack) {
               pack.images.forEach(function (image) {
                  if (!images.includes(image)) {
                     images.push(image)
                  }
               })
            })
            return images
         },
         selectPackaging: function (code) {
            // select the first package with given code
            this.selectedPackaging = this.packaging.find(function (pack) {
               return pack.code === code
            })
         },
         getSubstitutionViews: function (productViewOptions) {
            productViewOptions = getObject(productViewOptions)
            var _this = this
            var staffPicksPromise
            var bySearchPromise
            var hasActiveFilteredPackaging = function (productView) {
               return productView && productView.hasActiveFilteredPackaging
            }

            // Staff Picks
            if (!_this.substitutionStaffPickProductIds.length) {
               staffPicksPromise = $q.resolve([])
            } else {
               var productIdsAndCodes = _this.substitutionStaffPickProductIds.map(function (productId) {
                  return {id: productId}
               })
               staffPicksPromise = getProductViews(productIdsAndCodes, productViewOptions, true)
            }

            // By Search
            var filters = [
               'NOT packaging.tags:out-for-season',
               'NOT packaging.tags:out-long-term',
               'NOT packaging.tags:discontinued',
            ]
            _this.substitutionStaffPickProductIds.forEach(function (productId) {
               filters.push('NOT objectID:' + productId)
            })
            var searchOptions = {
               restrictSearchableAttributes:
                  'primary-category-names,primary-category-keywords,category-names,category-keywords,name,keywords',
               attributesToHighlight: '',
               attributesToRetrieve: productData.defaultAlgoliaAttributes.join(),
               hitsPerPage: 5, // Always show the top 5 search results after Staff Picks
               page: 0,
               queryType: 'prefixNone',
               removeStopWords: ['en'],
               typoTolerance: false,
               ignorePlurals: false,
               optionalFilters: ['isPromoted:true'],
            }
            if (productViewOptions.hasOwnProperty('inStockOn')) {
               var inStockFilter = productData.getAlgoliaStockFilters(productViewOptions.inStockOn).inStock
               filters.push(inStockFilter)
            }

            var searchTerm = util.normalizeSearchTerm(_this.name)
            if (_this.categoryView) {
               var maybeOptionalWords = searchTerm.split(' ')
               searchTerm = util.normalizeSearchTerm(_this.categoryView.name)
               var nonOptionalWords = searchTerm.split(' ')
               if (_this.categoryView.ancestorViews) {
                  if (_this.categoryView.ancestorViews.length > 2) {
                     maybeOptionalWords = searchTerm.split(' ').concat(maybeOptionalWords)
                     var parentCategory = _this.categoryView.ancestorViews[_this.categoryView.ancestorViews.length - 1]
                     searchTerm = util.normalizeSearchTerm(parentCategory.name)
                     nonOptionalWords = searchTerm.split(' ')
                  }
                  filters.push('category-ids:' + _this.categoryView.ancestorViews[0].id)
               }
               var optionalWords = util.distinct(maybeOptionalWords).reduce(function (optionalWords, word) {
                  if (word && !nonOptionalWords.includes(word)) {
                     optionalWords.push(word)
                  }
                  return optionalWords
               }, [])
               searchOptions.optionalWords = optionalWords
               searchTerm = searchTerm + ' ' + optionalWords.join(' ')
            } else {
               searchOptions.removeWordsIfNoResults = 'allOptional'
            }
            searchOptions.filters = filters.join(' AND ')
            bySearchPromise = productData
               .searchProducts(productData.sortBy.relevance, searchTerm, searchOptions, true)
               .then(function (response) {
                  return $q.map(response.hits, function (product) {
                     return toProductView(product, productViewOptions)
                  })
               })

            return $q.allSettled([staffPicksPromise, bySearchPromise]).then(function (results) {
               return {
                  staffPicks: (results[0].value || []).filter(hasActiveFilteredPackaging),
                  bySearch: (results[1].value || []).filter(hasActiveFilteredPackaging),
               }
            })
         },
         hasActiveSubstitution: function (options) {
            return this.getSubstitutionViews(options).then(function (substitutionViews) {
               return substitutionViews.staffPicks.length || substitutionViews.bySearch.length
            })
         },
      }

      //================================================================================
      // Product Views
      //================================================================================

      function toProductView(product, options) {
         if (!product) {
            return
         }
         var productView = createView(product, _productViewPrototype)

         productView.description = util.unfurlYouTube(productView.description)
         productView.inactiveDescription = util.unfurlYouTube(productView.inactiveDescription)
         productView.name = util.trimStart(productView.name, '@')

         options = getObject(options)

         options.priceSettings = options.priceSettings || personData.defaultPriceSettings

         var maybeProductFromApiPromise
         if (options.codes) {
            var missingProductCode = options.codes.find(function (code) {
               return !product.packaging.some(function (packaging) {
                  return packaging.code === code
               })
            })
            if (missingProductCode) {
               maybeProductFromApiPromise = productData.getProductByCode(missingProductCode, true)
            }
         }

         var maybeCategoryViewPromise
         if (options.categoryView && productView.primaryCategoryId) {
            maybeCategoryViewPromise = getCategoryView(productView.primaryCategoryId, options.categoryView)
         }

         var viewPromises = [$q.resolve(maybeProductFromApiPromise), $q.resolve(maybeCategoryViewPromise)]

         return $q.allSettled(viewPromises).then(function (results) {
            return assembleProductView(productView, results, options)
         })
      }

      function assembleProductView(productView, results, options) {
         angular.extend(productView, {
            categoryView: results[1].value,
         })

         var productFromApi = results[0].value
         if (productFromApi && productFromApi.id === productView.id) {
            productView.packaging = productFromApi.packaging
         }

         var packaging = productView.packaging.map(function (item) {
            var packageView = toPackageView(item, {priceSettings: options.priceSettings})

            if (!packageView) {
               return
            }

            // Maybe remove inactive or active packaging
            if (
               (packageView.isInactive && options.removeInactivePackaging) ||
               (!packageView.isInactive && options.removeActivePackaging)
            ) {
               return
            }

            var packageRequired =
               options.codes &&
               options.codes.some(function (code) {
                  return code === packageView.code
               })

            if (!packageRequired) {
               // Remove packaging: inactive bargain bin, new not on sale, distributor, in house, and not for sale
               // (unless the code is in `options.codes`)
               if (
                  (packageView.isInactive && packageView.isBargainBin) ||
                  packageView.isNewNotOnSale ||
                  packageView.isNotForSale ||
                  packageView.isInHouse ||
                  packageView.isDistributor
               ) {
                  return
               }
            }

            return packageView
         })

         packaging = util.compact(packaging)

         // sort packaging in order of median price
         packaging.sort(function (a, b) {
            return a.weight.net - b.weight.net
         })

         productView.filteredByTags = util.isArrayOfStrings(options.tags) && options.tags.length > 0
         productView.filteredByInStock = options.hasOwnProperty('inStockOn')
         productView.filteredByOutOfStock = options.hasOwnProperty('outOfStockOn')
         productView.filteredByBonusRewards = options.bonusRewards
         productView.filteredByClearance = options.clearance
         productView.filteredByCodes = util.isArrayOfStrings(options.codes) && options.codes.length > 0

         if (productView.filteredByOutOfStock) {
            productView.outOfStockCount = 0
         }

         if (productView.filtered) {
            productView.filterMatchCount = 0
         }

         productView.trustpilotNumberOfReviews = 0
         var trustpilotStarsTotal = 0

         packaging.forEach(function (packageView) {
            packageView.labels = packagedProductTagService.getLabels(packageView, options.priceSettings.priceLevel)

            if (packageView.trustpilotNumberOfReviews) {
               productView.trustpilotNumberOfReviews += packageView.trustpilotNumberOfReviews
               trustpilotStarsTotal += packageView.trustpilotNumberOfReviews * packageView.trustpilotStarsAverage
            }

            if (packageView.isBargainBin) {
               // bargain bin packaging doesn't have images
               // try to find images from non-BB packaging of the same size
               var sameSizePackage = packaging.find(function (pack) {
                  return !pack.isBargainBin && pack.size === packageView.size
               })
               if (sameSizePackage) {
                  packageView.images = sameSizePackage.images
               }
            }

            packageView.productView = productView

            if (productView.filteredByTags) {
               packageView.filterMatch = !options.tags.some(function (tag) {
                  return !packageView.tags.some(function (packTag) {
                     return packTag === tag
                  })
               })
            }

            if (productView.filteredByInStock) {
               packageView.filterMatch =
                  packageView.filterMatch !== false && !packageView.isOutOfStockOn(options.inStockOn)
            }

            if (productView.filteredByOutOfStock) {
               var isOutOfStock = packageView.isOutOfStockOn(options.outOfStockOn)
               if (isOutOfStock) {
                  productView.outOfStockCount++
               }
               packageView.filterMatch = packageView.filterMatch !== false && isOutOfStock
            }

            if (productView.filteredByBonusRewards) {
               var isBonusRewards =
                  packageView.rewardsEnabled && packageView.userPrice.rewardsRate > options.priceSettings.rewardsRate
               packageView.filterMatch = packageView.filterMatch !== false && isBonusRewards
            }

            if (productView.filteredByClearance) {
               packageView.filterMatch = packageView.filterMatch !== false && packageView.isClearance
            }

            if (productView.filteredByCodes) {
               packageView.filterMatch =
                  packageView.filterMatch !== false &&
                  options.codes.some(function (code) {
                     return code === packageView.code
                  })
            }

            if (packageView.filterMatch) {
               productView.filterMatchCount++

               // select the first filter match
               if (!productView.selectedPackaging) {
                  productView.selectedPackaging = packageView
               }
            }
         })

         productView.packaging = packaging

         if (productView.trustpilotNumberOfReviews) {
            productView.trustpilotStarsAverage = trustpilotStarsTotal / productView.trustpilotNumberOfReviews
         }

         return productView
      }

      function getProductViewsByCode(codes, options) {
         return productData.getProductsByCode(codes).then(function (result) {
            return $q.map(codes, function (code) {
               var product = result.productsByCode[code]
               // If a product was found, return its product view
               if (product) {
                  var opts = angular.extend(
                     {
                        codes: [code],
                     },
                     options
                  )
                  return toProductView(product, opts)
               }
            })
         })
      }

      function getProductViewByCode(code, options) {
         return getProductViewsByCode([code], options).then(function (productViews) {
            return productViews[0]
         })
      }

      function getProductFullView(productId, options) {
         var productIdAndCode = {
            id: productId,
            code: options && options.code,
         }
         return productData.getProduct(productIdAndCode, true).then(function (product) {
            return toProductView(product, options)
         })
      }

      function getProductView(productId, options) {
         var productIdAndCode = {
            id: productId,
            code: options && options.code,
         }
         return productData.getProduct(productIdAndCode).then(function (product) {
            return toProductView(product, options)
         })
      }

      function getProductViews(productIdsAndCodes, options, noApiFallback) {
         return productData.getProducts(productIdsAndCodes, noApiFallback).then(function (result) {
            return $q.map(productIdsAndCodes, function (idAndCode) {
               var code = idAndCode.code
               var productViewOptions = options
               if (code) {
                  productViewOptions = angular.extend({}, options, {
                     codes: [code],
                  })
               }
               return toProductView(result.productsById[idAndCode.id], productViewOptions)
            })
         })
      }

      //================================================================================
      // Reward View Prototype
      //================================================================================

      var _rewardViewPrototype = {
         get available() {
            return util.pennyRound(this['total-credit'] + this['total-debit'] + this['total-pending'])
         },
      }

      //================================================================================
      // Reward Views
      //================================================================================

      function toRewardView(reward) {
         if (!reward) {
            return
         }
         return createView(reward, _rewardViewPrototype)
      }

      function toRewardViews(rewards) {
         return rewards.map(toRewardView)
      }

      function getRewardViews(userId, bypassCache, start, limit) {
         return rewardData.getRewardEntries(userId, bypassCache, start, limit).then(toRewardViews)
      }

      function getRewardsTotalsView(userId, bypassCache) {
         return rewardData
            .getLastOne(userId, bypassCache)
            .then(toRewardView)
            .then(function (lastRewardView) {
               if (!lastRewardView) {
                  return {
                     credit: 0,
                     debit: 0,
                     pending: 0,
                     available: 0,
                  }
               }

               return {
                  credit: lastRewardView['total-credit'],
                  debit: lastRewardView['total-debit'],
                  pending: lastRewardView['total-pending'],
                  available: lastRewardView.available,
               }
            })
      }

      //================================================================================
      // Stop View Prototype
      //================================================================================

      var _stopViewPrototype = {
         get stopDropShippingFeePercent() {
            return this['drop-shipping-fee'] ? this['drop-shipping-fee'].percent : 0
         },
         get isPastCutoff() {
            return this.tripView && this.tripView.isPastCutoff
         },
         get isExtendedCutoff() {
            return this.isPastCutoff && this['short-drop']
         },
         get cutoffOrExtendedCutoffDate() {
            if (!this.tripView) {
               return
            }
            return this.isExtendedCutoff ? this.tripView.extendedCutoffDate : this.tripView.cutoffDate
         },
         get cutoffOrExtendedCutoffCountdown() {
            if (!this.tripView) {
               return
            }
            return this.isExtendedCutoff ? this.tripView.extendedCutoffCountdown : this.tripView.cutoffCountdown
         },
         get isPastExtendedCutoff() {
            if (!this.tripView) {
               return
            }
            return this.isExtendedCutoff && this.tripView.isPastExtendedCutoff
         },
         get isPastFinalCutoff() {
            return (this.isPastCutoff && !this.isExtendedCutoff) || (this.isPastExtendedCutoff && this.isExtendedCutoff)
         },
         canShop: function (isSuperUser) {
            if (!this.tripView) {
               return
            }
            return orderService.canShop(
               this.tripView.cutoffDate,
               this.tripView.confirmed,
               this['short-drop'],
               isSuperUser
            )
         },
      }

      //================================================================================
      // Stop Views
      //================================================================================

      function toStopView(stop, options, bypassCache) {
         if (!stop) {
            return
         }

         var stopView = createView(stop, _stopViewPrototype)

         if (stop.actualDelivery) {
            stopView.deliveryDateType = 'actual'
         } else if (stop.finalizedDelivery) {
            stopView.deliveryDateType = 'finalized'
         } else {
            stopView.deliveryDateType = 'estimated'
         }

         // Any possible finalized delivery flag is only relevant if there's no actual delivery value.
         // Ensure that the flag does not exist if there is an actual delivery.
         if (stop.finalizedDeliveryFlag && stop.actualDelivery) {
            delete stopView.finalizedDeliveryFlag
         }

         stopView.deliveryDate = new Date(stop.actualDelivery || stop.finalizedDelivery || stop.estimatedDelivery)

         if (stopView.deliveryDateType === 'estimated') {
            var estimatedDeliveryDate = new Date(stop.estimatedDelivery)

            stopView.sundayBeforeEstimatedDelivery = util.getPreviousSundayIfNotSunday(estimatedDeliveryDate)

            stopView.sundayAfterEstimatedDelivery = util.getFollowingSunday(estimatedDeliveryDate)
         }

         var assumeDeliveredBefore = new Date('12/1/2021')
         if (stopView.deliveryDateType !== 'actual' && assumeDeliveredBefore > stopView.deliveryDate) {
            stopView.assumeDelivered = true
         }

         if (!options) {
            return stopView
         }

         var maybeTripViewPromise
         var maybeDropViewPromise
         var maybeOrderViewsPromise

         if (options.tripView) {
            maybeTripViewPromise = getTripView(stop.trip, bypassCache)
         }
         if (options.dropView) {
            maybeDropViewPromise = getDropView(stop.drop, options.dropView, bypassCache)
         }
         if (options.orderViews) {
            maybeOrderViewsPromise = getOrderViewsByDropTrip(stop.drop, stop.trip, options.orderViews, bypassCache)
         }

         var viewPromises = [
            $q.resolve(maybeTripViewPromise),
            $q.resolve(maybeDropViewPromise),
            $q.resolve(maybeOrderViewsPromise),
         ]

         return $q.all(viewPromises).then(function (results) {
            angular.extend(stopView, {
               tripView: results[0],
               dropView: results[1],
               orderViews: results[2],
            })
            return stopView
         })
      }

      function toStopViews(stops, options, bypassCache) {
         return $q.map(stops, function (stop) {
            return toStopView(stop, options, bypassCache)
         })
      }

      function getStopView(dropId, tripId, options, bypassCache) {
         return stopData.getStop(dropId, tripId, bypassCache).then(function (stop) {
            return toStopView(stop, options, bypassCache)
         })
      }

      function getStopViewsByDropIdTripIds(dropIdTripIds, options, bypassCache) {
         return stopData.getStopsByDropIdTripIds(dropIdTripIds, bypassCache).then(function (stops) {
            return toStopViews(stops, options, bypassCache)
         })
      }

      function getStopViewsByDropId(dropId, options, bypassCache) {
         return tripData.getUnconfirmedTripIdsByDropId(dropId, bypassCache).then(function (tripIds) {
            var dropIdTripIds = tripIds.map(function (tripId) {
               return {
                  dropId: dropId,
                  tripId: tripId,
               }
            })
            return getStopViewsByDropIdTripIds(dropIdTripIds, options, bypassCache)
         })
      }

      //================================================================================
      // Trip View Prototype
      //================================================================================

      var _tripViewPrototype = {
         get cutoffCountdown() {
            if (this.cutoff) {
               return $filter('countdown')(this.cutoffDate)
            }
         },
         get extendedCutoffCountdown() {
            if (this.cutoff) {
               return $filter('countdown')(this.extendedCutoffDate)
            }
         },
         get isPastCutoff() {
            if (this.cutoff) {
               var now = new Date()
               return this.cutoffDate <= now
            }
            return false
         },
         get isApproachingCutoff() {
            if (this.cutoff) {
               var approachingCutoffDate = new Date(this.cutoffDate - 15 * 60000)
               var now = new Date()
               return approachingCutoffDate <= now && now < this.cutoffDate
            }
            return false
         },
         get isPastExtendedCutoff() {
            if (this.cutoff) {
               var now = new Date()
               return this.extendedCutoffDate <= now
            }
            return false
         },
         get isConfirmed() {
            return this.confirmed
         },
         get hasShipped() {
            return this.shipped
         },
         getNextTripOnRoute: function () {
            return tripData.getNextTripOnRouteAfterCutoff(this.route, this.extendedCutoffDate.toISOString())
         },
      }

      //================================================================================
      // Trip Views
      //================================================================================

      function toTripView(trip) {
         if (!trip) {
            return
         }
         var tripView = createView(trip, _tripViewPrototype)
         if (tripView.cutoff) {
            tripView.cutoffDate = new Date(tripView.cutoff)
            tripView.extendedCutoffDate = tripService.getExtendedCutoffDate(tripView.cutoffDate)
         }
         if (tripView['pick-date']) {
            tripView.pickDate = new Date(tripView['pick-date'])
         }
         return tripView
      }

      function getTripView(tripId, bypassCache) {
         return tripData.getTrip(tripId, bypassCache).then(toTripView)
      }

      //================================================================================

      return {
         createView: createView,

         // AffiliateReferral Views
         getAffiliateReferralViews: getAffiliateReferralViews,

         // AccountEntry Views
         getAccountEntryViews: getAccountEntryViews,

         // Address Views
         getUserAddressViews: getUserAddressViews,

         // Category Views
         toCategoryViews: toCategoryViews,
         getCategoryView: getCategoryView,
         getCategoryViews: getCategoryViews,

         // Drop Views
         getDropView: getDropView,
         getUserActiveDropViews: getUserActiveDropViews,
         getActiveDropMembershipActiveDropViews: getActiveDropMembershipActiveDropViews,
         getCoordinatorDropViews: getCoordinatorDropViews,

         // Drop Membership Views
         getActiveDropMembershipViewsWithActiveDrops: getActiveDropMembershipViewsWithActiveDrops,

         // Email Views
         getUserEmailViews: getUserEmailViews,

         // Order Views
         toOrderView: toOrderView,
         toOrderViews: toOrderViews,
         getOrderView: getOrderView,
         getUnresolvedOrderViews: getUnresolvedOrderViews,
         getResolvedOrderViews: getResolvedOrderViews,

         // PaymentMethod Views
         getActivePaymentMethodViews: getActivePaymentMethodViews,

         // Person Views
         getPersonView: getPersonView,
         getPersonViews: getPersonViews,

         // Product Views
         toProductView: toProductView,
         getProductFullView: getProductFullView,
         getProductView: getProductView,
         getProductViews: getProductViews,
         getProductViewByCode: getProductViewByCode,
         getProductViewsByCode: getProductViewsByCode,

         // Reward Views
         getRewardsTotalsView: getRewardsTotalsView,
         getRewardViews: getRewardViews,

         // Stop Views
         toStopView: toStopView,
         toStopViews: toStopViews,

         // Trip Views
         toTripView: toTripView,
      }
   }
})(angular)
