;(function (angular, undefined) {
   'use strict'

   angular.module('app').directive('asShipmentSelect', shipmentSelect)

   function shipmentSelect() {
      return {
         controller: shipmentSelectController,
         templateUrl: 'partials/shipment_select.htm',
         scope: {
            orderState: '=',
            selectShipment: '&',
            selectedParcelOptions: '=?',
            parentFeaturePath: '@',
            isEmbeddedInCheckout: '=',
            preselectD2hTab: '@?',
         },
      }
   }

   function shipmentSelectController(
      $scope,
      $q,
      $timeout,
      util,
      appState,
      alerts,
      addressService,
      viewService,
      dropService,
      modalService,
      orderData
   ) {
      var _isInitializing = true
      var _userState = ($scope.userState = appState.userState)
      var _userDropIds
      var _suggestedDrop
      var _ui = {
         isShowingDropMap: false,
         isShowingAddressSelectUi: false,
         allowNext: false,
      }
      var _shippingTypes = {
         drop: 'drop',
         parcel: 'parcel',
         d2h: 'd2h',
      }

      $scope.ui = _ui
      $scope.shippingTypes = _shippingTypes
      $scope.featurePath = $scope.parentFeaturePath + ':shipmentSelect'
      // For when no addresses exist yet
      $scope.address = {}
      $scope.includePhone = true

      //================================================================================
      // GENERAL
      //================================================================================

      function hasParcelEstimates() {
         return $scope.parcelOptions && $scope.parcelOptions.all && $scope.parcelOptions.all.length
      }

      function isShippingType(shippingType) {
         return _ui.chosenShippingType === shippingType
      }

      $scope.toggleSelectedShippingType = function (toggleTo) {
         _ui.chosenShippingType = toggleTo

         if ($scope.orderState && $scope.orderState.order && $scope.orderState.order.dropToHomeDeliveryAddress) {
            _d2hState.memStoreOfSelectedAddress = angular.extend({}, $scope.orderState.order.dropToHomeDeliveryAddress)
         } else {
            _d2hState.memStoreOfSelectedAddress = undefined
         }

         switch (toggleTo) {
            case _shippingTypes.drop:
               if ($scope.userDropViews && $scope.userDropViews.length) {
                  toggleDropMapVisibility(false)
               } else {
                  toggleDropMapVisibility(true)
               }
               break

            case _shippingTypes.d2h:
               if ($scope.orderState && $scope.orderState.order && $scope.orderState.order.isD2h) {
                  _ui.isShowingAddressSelectUi = false
               } else {
                  _ui.isShowingAddressSelectUi = true
               }
               toggleDropMapVisibility(false)
               break

            case _shippingTypes.parcel:
               if (
                  $scope.orderState &&
                  $scope.orderState.order &&
                  $scope.orderState.order.isParcelCarrier &&
                  $scope.selectedParcelOptions
               ) {
                  _ui.isShowingAddressSelectUi = false
               } else {
                  _ui.isShowingAddressSelectUi = true
               }
               break
         }

         maybeAllowNextCheckoutStep()
      }

      function maybeAllowNextCheckoutStep() {
         // This is only relevant when the directive is embedded in checkout (on /checkout/shipping).
         // Guarding for this here is DRYer than adding conditions to all instances where it's called.
         if ($scope.isEmbeddedInCheckout && $scope.orderState) {
            var allowNext

            if ($scope.orderState.order.isParcelCarrier) {
               allowNext =
                  isShippingType(_shippingTypes.parcel) &&
                  (!requiresAcceptedPerishableShippingWarning() ||
                     $scope.selectedParcelOptions.perishableShippingWarningAccepted) &&
                  !$scope.parcelCarrierEstimatesError &&
                  hasParcelEstimates()
            } else if ($scope.orderState.order.isDropAndNotD2h) {
               allowNext = !_ui.isShowingDropMap && _ui.selectedDropView && isShippingType(_shippingTypes.drop)
            } else if ($scope.orderState.order.isD2h) {
               allowNext =
                  isShippingType(_shippingTypes.d2h) &&
                  isSameAddressMinKeys(
                     $scope.orderState.order.dropToHomeDeliveryAddress,
                     _d2hState.memStoreOfSelectedAddress
                  )
            }

            $scope.$parent.allowNext = allowNext
            _ui.allowNext = allowNext
         }
      }
      $scope.maybeAllowNextCheckoutStep = maybeAllowNextCheckoutStep

      //================================================================================
      // DROP MAP SPECIFIC (`as-drop-finder`)
      //================================================================================

      $scope.dropMapState = {
         dropShippingFee: undefined,
      }

      $scope.selectedDropCleared = function () {
         $scope.dropMapState.dropShippingFee = undefined
         $scope.$digest()
      }

      $scope.selectDropForShipmentFromDropMap = function (drop) {
         var pending = !_userState.superUserId && drop.exclusivity !== 'open'

         function afterJoiningDropOrIfAlreadyJoined() {
            return selectDropForShipment(drop)
               .then(initDrops)
               .then(function () {
                  toggleDropMapVisibility(false)
               })
         }

         if (_userDropIds && _userDropIds.length && _userDropIds.includes(drop.id)) {
            return afterJoiningDropOrIfAlreadyJoined()
         }

         var maybeConfirm = drop.exclusivity === 'closed' ? modalService.confirmJoiningClosedDrop() : $q.resolve()

         return maybeConfirm
            .then(function () {
               $scope.dropsLoading = true
               return dropService
                  .addDropMembership(_userState.id, drop.id, pending)
                  .then(afterJoiningDropOrIfAlreadyJoined)
                  .catch(alerts.error)
            })
            .catch($q.reject)
      }

      function toggleDropMapVisibility(toggleTo) {
         _ui.isShowingDropMap = toggleTo
         maybeAllowNextCheckoutStep()
      }
      $scope.toggleDropMapVisibility = toggleDropMapVisibility

      //================================================================================
      // DROP SPECIFIC (not drop finder and not Drop-to-Home)
      //================================================================================

      function augmentStopView(stopView) {
         Object.defineProperty(stopView, 'isSelected', {
            get: function () {
               if ($scope.orderState && $scope.orderState.order) {
                  return $scope.orderState.order.drop === this.drop && $scope.orderState.order.trip === this.trip
               }
            },
         })
         return stopView
      }

      function augmentStopViews(stopViews) {
         stopViews.forEach(augmentStopView)
         return stopViews
      }

      function augmentDropView(dropView) {
         dropView.stopViewsPromise
            .then(augmentStopViews)
            // TODO: user error message
            .catch(console.error.bind(console))

         Object.defineProperty(dropView, 'selectedStopIndex', {
            get: function () {
               if (this.stopViews) {
                  return util.findIndexByPropertyValue(this.stopViews, 'isSelected', true)
               }
            },
         })

         Object.defineProperty(dropView, 'selectedStopView', {
            get: function () {
               if (this.stopViews) {
                  return util.findByPropertyValue(this.stopViews, 'isSelected', true)
               }
            },
         })

         return dropView
      }

      function augmentDropViews(dropViews) {
         dropViews.forEach(augmentDropView)
         return dropViews
      }

      function setDropShippingEstimate() {
         $scope.dropShippingEstimateLoading = true
         if ($scope.userDropViews && $scope.userDropViews.length) {
            $q.map($scope.userDropViews, function (drop) {
               return drop.stopViewsPromise.then(function () {
                  return drop.nextShoppableStopView(_userState.superUserId)
               })
            })
               .then(util.compact)
               .then(function (nextShoppableStopViews) {
                  $scope.dropShippingEstimateLoading = false
                  if (nextShoppableStopViews.length) {
                     var dropShippingFees = nextShoppableStopViews
                        .map(function (stop) {
                           return stop.stopDropShippingFeePercent
                        })
                        .sort()
                     $scope.dropEstimatedShipping = {
                        min: dropShippingFees[0],
                        max: dropShippingFees[dropShippingFees.length - 1],
                     }
                  } else {
                     $scope.dropEstimatedShipping = null
                  }
               })
         } else if (_suggestedDrop) {
            _suggestedDrop.stopViewsPromise.then(function () {
               var nextShoppableStop = _suggestedDrop.nextShoppableStopView(_userState.superUserId)
               if (nextShoppableStop) {
                  $scope.dropEstimatedShipping = {
                     min: nextShoppableStop.stopDropShippingFeePercent,
                     max: nextShoppableStop.stopDropShippingFeePercent,
                  }
               } else {
                  $scope.dropEstimatedShipping = null
               }
               $scope.dropShippingEstimateLoading = false
            })
         } else {
            $scope.dropEstimatedShipping = null
            $scope.dropShippingEstimateLoading = false
         }
      }

      //================================================================================
      // DROP-TO-HOME SPECIFIC
      //================================================================================

      // NOTE: Only "qualifying" estimates are loaded and considered here.

      var _d2hState = {
         areEstimatesLoaded: false,
         dropIds: undefined, // with qualifying drop-to-home option(s), for all addresses
         memStoreOfSelectedAddress: undefined,
         estimates: [],
         estimateCostRange: undefined,
         estimateCostRangeOfUnjoinedDropsForSelectedAddress: undefined,
         estimatesByDropId: [],
         clearSelectedAddress: function () {
            _d2hState.memStoreOfSelectedAddress = undefined
         },
         isDropSelected: function (drop) {
            if (
               drop.selectedStopView &&
               $scope.orderState.order.isD2h &&
               $scope.orderState.order.dropView.id === drop.id &&
               (!_d2hState.memStoreOfSelectedAddress ||
                  isSameAddressMinKeys(
                     $scope.orderState.order.dropToHomeDeliveryAddress,
                     _d2hState.memStoreOfSelectedAddress
                  ))
            ) {
               return true
            } else {
               return false
            }
         },
         doesDropHaveValidEstimateForSelectedAddress: function (drop) {
            return (
               _d2hState.estimatesByDropId[drop.id] &&
               _d2hState.estimatesByDropId[drop.id].length &&
               _d2hState.estimatesByDropId[drop.id].some(function (estimate) {
                  return isSameAddressMinKeys(estimate.dropToHomeDeliveryAddress, _d2hState.memStoreOfSelectedAddress)
               })
            )
         },
         doesAddressHaveValidEstimate: function (address) {
            return (
               address &&
               _d2hState.estimates &&
               _d2hState.estimates.length &&
               _d2hState.estimates.some(function (estimate) {
                  return isSameAddressMinKeys(estimate.dropToHomeDeliveryAddress, address)
               })
            )
         },
         getEstimateForSelectedAddressByDropId: function (dropId) {
            return getAddressEstimateByDropId(dropId, _d2hState.memStoreOfSelectedAddress)
         },
         getEstimatesForAddress: function (address) {
            return (
               _d2hState.estimates &&
               _d2hState.estimates.length &&
               _d2hState.estimates.filter(function (estimate) {
                  return isSameAddressMinKeys(estimate.dropToHomeDeliveryAddress, address)
               })
            )
         },
         getEstimatesForSelectedAddress: function () {
            return _d2hState.getEstimatesForAddress(_d2hState.memStoreOfSelectedAddress)
         },
         getCostRangeForAddress: function (address) {
            var addressEstimates = _d2hState.getEstimatesForAddress(address)
            if (!addressEstimates || !addressEstimates.length) {
               return
            }
            return util.mapToLowAndHigh(util.mapToProperty('cost', addressEstimates))
         },
         getDropIdsForSelectedAddress: function () {
            var estimatesForSelectedAddress = _d2hState.getEstimatesForSelectedAddress()
            if (!estimatesForSelectedAddress || !estimatesForSelectedAddress.length) {
               return
            }
            return util.mapToProperty('dropId', estimatesForSelectedAddress)
         },
         doesUserBelongToDropWithEstimateForSelectedAddress: function () {
            var dropIdsForSelectedAddress = _d2hState.getDropIdsForSelectedAddress()
            if (!dropIdsForSelectedAddress) {
               return false
            }

            // Return `true` or `false` on whether `dropIdsForSelectedAddress` contains at least one id that is not in `userDropIds`
            return dropIdsForSelectedAddress.some(function (dropId) {
               return _userDropIds.includes(dropId)
            })
         },
         shouldShowJoinNewDropButton: function () {
            // Returns a boolean based on the condition:
            // Does the selected address have at least one valid estimate from at least one drop that the user has not yet joined?

            var dropIdsForSelectedAddress = _d2hState.getDropIdsForSelectedAddress()
            if (!dropIdsForSelectedAddress) {
               return false
            }

            // Return `true` or `false` on whether `dropIdsForSelectedAddress` contains at least one id that is not in `userDropIds`
            return dropIdsForSelectedAddress.some(function (dropId) {
               return !_userDropIds.includes(dropId)
            })
         },
         countOfUnjoinedDropsWithEstimateForSelectedAddress: function () {
            var estimates = _d2hState.getEstimatesForSelectedAddress()
            if (!estimates || !estimates.length) {
               return 0
            }

            var dropIdsWithQualifyingEstimate = Array.from(new Set(util.mapToProperty('dropId', estimates)))

            if (!_userDropIds || !_userDropIds.length) {
               return dropIdsWithQualifyingEstimate.length
            }

            return dropIdsWithQualifyingEstimate.filter(function (dropId) {
               return !_userDropIds.includes(dropId)
            }).length
         },
      }
      $scope.d2hState = _d2hState

      function d2hMaybeToggleDropMapVisibility() {
         // If the user is a member of drops that have d2h for the selected address, make sure the map is not showing.
         // Otherwise, show the map.
         if (_d2hState.doesUserBelongToDropWithEstimateForSelectedAddress()) {
            toggleDropMapVisibility(false)
         } else {
            toggleDropMapVisibility(true)
         }
      }

      function getAddressEstimateByDropId(dropId, address) {
         return (
            _d2hState.estimatesByDropId[dropId] &&
            _d2hState.estimatesByDropId[dropId].length &&
            _d2hState.estimatesByDropId[dropId].find(function (estimate) {
               return isSameAddressMinKeys(estimate.dropToHomeDeliveryAddress, address)
            })
         )
      }

      function getJoinedDropWithCheapestD2hOptionForSelectedAddress() {
         var estimates = _d2hState.getEstimatesForSelectedAddress()
         if (!estimates || !estimates.length || !$scope.userDropViews || !$scope.userDropViews.length) {
            return
         }

         var estimatesViaJoinedDrops = estimates.filter(function (estimate) {
            return _userDropIds.includes(estimate.dropId)
         })

         if (!estimatesViaJoinedDrops.length) {
            return
         }

         var cheapestEstimateViaJoinedDrops = estimatesViaJoinedDrops.reduce(function (previous, current) {
            return previous.cost < current.cost ? previous : current
         })

         return $scope.userDropViews.find(function (dropView) {
            return dropView.id === cheapestEstimateViaJoinedDrops.dropId
         })
      }

      $scope.selectAddressForD2h = function (customerAddress) {
         // Note that here we're not yet assigning the address to the order because a Drop-to-Home order needs a drop (trip and stop).
         // We store the selection in memory until the user selects a drop.

         // If the user has already selected this address...
         if (
            _d2hState.memStoreOfSelectedAddress &&
            isSameAddressMinKeys(_d2hState.memStoreOfSelectedAddress, customerAddress)
         ) {
            d2hMaybeToggleDropMapVisibility()
            _ui.isShowingAddressSelectUi = false
            return
         }

         _d2hState.memStoreOfSelectedAddress = Object.assign({}, customerAddress)

         // This is necessary because otherwise the customerAddressId will get passed here and the backend will
         // consider this the *drop-to-home* address ID and fail.
         delete _d2hState.memStoreOfSelectedAddress.id

         var canBypassMaybeMapToggle
         // If there's already a selected drop (for "pickup" or "D2h") and that drop has a valid estimate for the newly-selected address,
         // auto-select that drop.
         //
         // Note that the D2h cost could be different, due to a change in distance between the drop and newly-selected address.
         //
         if (_ui.selectedDropView && _d2hState.doesDropHaveValidEstimateForSelectedAddress(_ui.selectedDropView)) {
            selectDropForShipment(_ui.selectedDropView)
            canBypassMaybeMapToggle = true
         } else {
            // Else, if there's no selected drop or the selected drop doesn't have a valid estimate for the newly-selected address,
            // auto-select the drop with the lowest cost estimate for the newly-selected address (if there is one).
            var dropToAutoSelect = getJoinedDropWithCheapestD2hOptionForSelectedAddress()
            if (dropToAutoSelect) {
               selectDropForShipment(dropToAutoSelect)
               canBypassMaybeMapToggle = true
            }
         }

         _ui.isShowingAddressSelectUi = false

         setEstimateCostRangeOfUnjoinedDropsForSelectedAddress()

         if (!canBypassMaybeMapToggle) {
            d2hMaybeToggleDropMapVisibility()
         }

         maybeAllowNextCheckoutStep()
      }

      function fetchD2hEstimates() {
         // NOTE: This only fetches "qualifying" estimates.

         if (!appState.userState.isDropToHomeDeliveryAllowed) {
            _d2hState.areEstimatesLoaded = true
            return
         }

         var promises = $scope.addresses.map(function (address) {
            return orderData.getD2hQualifyingEstimates($scope.orderState.id, address, true, true)
         })

         return $q.all(promises).then(function (estimateArraysGroupedByAddress) {
            _d2hState.estimates = estimateArraysGroupedByAddress.flat()

            if (!_d2hState.estimates || !_d2hState.estimates.length) {
               _d2hState.areEstimatesLoaded = true
               return
            }

            //
            // There are one or more valid estimates...
            //

            _d2hState.dropIds = util.mapToProperty('dropId', _d2hState.estimates)

            _d2hState.estimatesByDropId = util.groupBy(_d2hState.estimates, 'dropId')

            // Add the calculated drop shipping fee to each estimate
            _d2hState.estimates = _d2hState.estimates.map(function (estimate) {
               if (estimate.dropShippingFeePercent) {
                  estimate.dropShippingFeeCalculated = calculateDropShippingFeeFromPercent(
                     estimate.dropShippingFeePercent
                  )
               }
               // NOTE: This is the estimated total "cost" of shipping, including a possible drop shipping fee.
               estimate.cost = estimate.charge + (estimate.dropShippingFeeCalculated || 0)
               return estimate
            })

            _d2hState.estimateCostRange = util.mapToLowAndHigh(util.mapToProperty('cost', _d2hState.estimates))

            setEstimateCostRangeOfUnjoinedDropsForSelectedAddress()

            _d2hState.areEstimatesLoaded = true
         })
      }

      function setEstimateCostRangeOfUnjoinedDropsForSelectedAddress() {
         var allEstimates = _d2hState.getEstimatesForSelectedAddress()
         if (!allEstimates || !allEstimates.length) {
            return 0
         }

         var estimatesOfUnjoinedDrops = allEstimates.filter(function (estimate) {
            return !_userDropIds.includes(estimate.dropId)
         })

         _d2hState.estimateCostRangeOfUnjoinedDropsForSelectedAddress = util.mapToLowAndHigh(
            util.mapToProperty('cost', estimatesOfUnjoinedDrops)
         )
      }

      function calculateDropShippingFeeFromPercent(percent) {
         return (percent * $scope.orderState.order.linePrice) / 100 || 0
      }
      $scope.calculateDropShippingFeeFromPercent = calculateDropShippingFeeFromPercent

      $scope.d2hClickedChangeSelectedAddress = function () {
         toggleDropMapVisibility(false)
         _ui.isShowingAddressSelectUi = true
      }

      //================================================================================
      // SHARED BY DROP & DROP-TO-HOME
      //================================================================================

      $scope.clickedDropInDropSelectUi = function (drop) {
         return selectDropForShipment(drop)
      }

      function selectDropForShipment(drop) {
         var nextShoppableStopView = drop.nextShoppableStopView(_userState.superUserId)

         if (nextShoppableStopView) {
            return $scope
               .selectShipment({
                  destination: {
                     drop: nextShoppableStopView.drop,
                     trip: nextShoppableStopView.trip,
                     dropToHomeDeliveryAddress: isShippingType(_shippingTypes.d2h)
                        ? _d2hState.memStoreOfSelectedAddress
                        : undefined,
                  },
               })
               .then(function () {
                  _ui.selectedDropView = drop
                  maybeAllowNextCheckoutStep()
               })
         } else {
            _ui.selectedDropView = drop
            return $q.resolve()
         }
      }

      //================================================================================
      // PARCEL SPECIFIC
      //================================================================================

      var _parcelOptions = {
         howToPackage: undefined,
         show: $scope.selectedParcelOptions !== undefined,
         allowSplitShipments: false,
         loading: true,
         selected: {
            all: {},
            roomTemp: {},
            chilled: {},
         },
      }

      $scope.parcelOptions = _parcelOptions

      $scope.selectAddressForParcelCarrierDelivery = function (address) {
         $scope
            .selectShipment({
               destination: {
                  parcelCarrierDeliveryAddress: address,
               },
            })
            .then(function () {
               // This ensures no stale value (since the shipping method is parcel there should be no selected dropView)
               _ui.selectedDropView = undefined
            })
      }

      $scope.isSelectedAddressOrphaned = function () {
         return !$scope.addresses.some(function (address) {
            return addressService.isSameAddress($scope.orderState.order.address, address)
         })
      }

      function parseParcelCarrierEstimates(estimates) {
         if (!estimates) {
            return
         }

         // TODO: Consider abstracting this into a util function (to go into util.js) or use appropriate existing one
         var filterEstimateByClimateType = function (type) {
            return estimates.filter(function (estimate) {
               return estimate.climate === type
            })
         }

         var parcelOptionsAll = filterEstimateByClimateType('all')
         var parcelOptionsRoomTemp = filterEstimateByClimateType('roomTemp')
         var parcelOptionsChilled = filterEstimateByClimateType('chilled')

         var allCosts = util.mapToProperty('cost', parcelOptionsAll)
         var allMin = allCosts[0]
         var allMax = allCosts[allCosts.length - 1]
         var splitMin
         var splitMax

         _parcelOptions.all = parcelOptionsAll

         if (!_parcelOptions.all.length) {
            return
         }

         $scope.parcelEstimatedShipping = {
            min: allMin,
            max: allMax,
         }

         var allowSplitShipments = parcelOptionsRoomTemp.length && parcelOptionsChilled.length

         if (allowSplitShipments) {
            var costsForRoomTemp = util.mapToProperty('cost', parcelOptionsRoomTemp)
            var costsForChilled = util.mapToProperty('cost', parcelOptionsChilled)
            splitMin = costsForRoomTemp[0] + costsForChilled[0]
            splitMax = costsForRoomTemp[costsForRoomTemp.length - 1] + costsForChilled[costsForChilled.length - 1]
            if (splitMin < allMin) {
               $scope.parcelEstimatedShipping.min = splitMin
            }
            if (allMax < splitMax) {
               $scope.parcelEstimatedShipping.max = splitMax
            }
         }

         _parcelOptions.roomTemp = parcelOptionsRoomTemp
         _parcelOptions.chilled = parcelOptionsChilled
         _parcelOptions.allowSplitShipments = allowSplitShipments

         if ($scope.selectedParcelOptions) {
            var selectedParcelCarrierServices = $scope.selectedParcelOptions.parcelCarrierServices
            if (selectedParcelCarrierServices.all || !allowSplitShipments) {
               _parcelOptions.howToPackage = 'together'
            } else if (selectedParcelCarrierServices.roomTemp && selectedParcelCarrierServices.chilled) {
               _parcelOptions.howToPackage = 'split'
            } else {
               _parcelOptions.howToPackage = splitMin < allMin ? 'split' : 'together'
            }

            // Find the previously selected service within this estimate and select it
            // If the previously selected service is not found in this estimate, select the default (first) service
            var selectedAllService = _parcelOptions.selected.all.service || selectedParcelCarrierServices.all
            _parcelOptions.selected.all =
               _parcelOptions.all.find(function (option) {
                  return option.service === selectedAllService
               }) || parcelOptionsAll[0]

            if (allowSplitShipments) {
               var selectedRoomTempService =
                  _parcelOptions.selected.roomTemp.service || selectedParcelCarrierServices.roomTemp
               _parcelOptions.selected.roomTemp =
                  _parcelOptions.roomTemp.find(function (option) {
                     return option.service === selectedRoomTempService
                  }) || parcelOptionsRoomTemp[0]

               var selectedChilledService =
                  _parcelOptions.selected.chilled.service || selectedParcelCarrierServices.chilled
               _parcelOptions.selected.chilled =
                  _parcelOptions.chilled.find(function (option) {
                     return option.service === selectedChilledService
                  }) || parcelOptionsChilled[0]
            }
         }
      }

      function setSelectedParcelOptions() {
         if ($scope.selectedParcelOptions) {
            var selectedParcelCarrierServices = $scope.selectedParcelOptions.parcelCarrierServices
            if (_parcelOptions.howToPackage === 'together') {
               selectedParcelCarrierServices.all = _parcelOptions.selected.all.service
               delete selectedParcelCarrierServices.roomTemp
               delete selectedParcelCarrierServices.chilled
            } else {
               delete selectedParcelCarrierServices.all
               selectedParcelCarrierServices.roomTemp = _parcelOptions.selected.roomTemp.service
               selectedParcelCarrierServices.chilled = _parcelOptions.selected.chilled.service
            }
            commonParcelSelectionUpdateTasks()
         }
      }
      $scope.setSelectedParcelOptions = setSelectedParcelOptions

      $scope.handleParcelOptionSelection = function (climate, selected) {
         // Update selected object
         $scope.selectedParcelOptions.parcelCarrierServices[climate] = selected.service
         _parcelOptions.selected[climate] = selected

         $scope.selectedParcelOptions.perishableShippingWarningAccepted = false

         commonParcelSelectionUpdateTasks()
      }

      function commonParcelSelectionUpdateTasks() {
         // Update estimate
         setOrUpdateParcelOptionsTotal()

         // Maybe allow/prevent next checkout step
         maybeAllowNextCheckoutStep()
      }

      function setOrUpdateParcelOptionsTotal() {
         if (_parcelOptions.howToPackage === 'together') {
            $scope.parcelOptionsTotal = _parcelOptions.selected.all.cost
         } else {
            $scope.parcelOptionsTotal =
               parseFloat(_parcelOptions.selected.roomTemp.cost) + parseFloat(_parcelOptions.selected.chilled.cost)
         }
      }

      function requiresAcceptedPerishableShippingWarning() {
         if (_parcelOptions.howToPackage === 'together') {
            return _parcelOptions.selected.all['requires-accepted-perishable-shipping-warning']
         } else {
            return _parcelOptions.selected.chilled['requires-accepted-perishable-shipping-warning']
         }
      }
      $scope.requiresAcceptedPerishableShippingWarning = requiresAcceptedPerishableShippingWarning

      //================================================================================
      // Local utils
      //================================================================================

      function isSameAddressMinKeys(address1, address2) {
         return addressService.isSameAddress(address1, address2, true)
      }

      $scope.isSameAddressMinKeys = isSameAddressMinKeys

      //================================================================================
      // Initialization functions
      //================================================================================

      function initChosenShippingType() {
         if ($scope.orderState && $scope.orderState.order && $scope.orderState.order.hasDestination) {
            if ($scope.orderState.order.isParcelCarrier) {
               _ui.chosenShippingType = _shippingTypes.parcel
            } else if ($scope.orderState.order.isDrop) {
               _ui.chosenShippingType = _shippingTypes.drop
            } else if ($scope.orderState.order.isD2h) {
               _ui.chosenShippingType = _shippingTypes.d2h
            }
         }
      }

      function maybeInitOrder() {
         var maybeOrderStatePromise
         if ($scope.orderState) {
            maybeOrderStatePromise = $scope.orderState.promise
         }
         return $q.resolve(maybeOrderStatePromise).then(initChosenShippingType)
      }

      function initDrops() {
         $scope.dropsLoading = true
         return (
            viewService
               .getActiveDropMembershipActiveDropViews(
                  _userState.id,
                  {
                     stopViews: {
                        tripView: {},
                     },
                  },
                  true
               )
               .then(augmentDropViews)
               .then(function (dropViews) {
                  $scope.userDropViews = dropViews
                  _userDropIds = util.mapToProperty('id', dropViews)

                  $scope.usersD2hDrops = dropViews.filter(function (dropView) {
                     return dropView.hasHomeDelivery
                  })

                  if (!dropViews || !dropViews.length || !$scope.usersD2hDrops.length) {
                     _ui.isShowingDropMap = true
                  }

                  // Set selected drop view
                  if ($scope.userDropViews.length && $scope.orderState && $scope.orderState.order) {
                     _ui.selectedDropView = $scope.userDropViews.find(function (dropView) {
                        return dropView.id === $scope.orderState.order.drop
                     })
                  }

                  setDropShippingEstimate()
               })
               // TODO: user error message
               .catch(console.error.bind(console))
               .finally(function () {
                  $scope.dropsLoading = false
               })
         )
      }

      function fetchParcelCarrierEstimates(address, forceRefresh) {
         var order = $scope.orderState && $scope.orderState.order

         if (order && address) {
            // Get parcel carrier estimates for the passed address
            _parcelOptions.loading = true
            _ui.isShowingAddressSelectUi = false

            $scope.parcelCarrierEstimatesError = false
            var parcelCarrierEstimates = order.parcelCarrierEstimates
            if (!parcelCarrierEstimates || forceRefresh) {
               parcelCarrierEstimates = orderData.getParcelCarrierEstimates(order.id, address).catch(function () {
                  $scope.parcelCarrierEstimatesError = true
               })
            } else if (order.errors.parcelCarrierEstimates) {
               $scope.parcelCarrierEstimatesError = true
            }

            return $q
               .resolve(parcelCarrierEstimates)
               .then(parseParcelCarrierEstimates)
               .then(setSelectedParcelOptions)
               .catch(console.error.bind(console))
               .finally(function () {
                  _parcelOptions.loading = false
               })
         }
      }

      function initSelectedShippingMethod() {
         if ($scope.preselectD2hTab) {
            $scope.toggleSelectedShippingType(_shippingTypes.d2h)
         } else {
            if ($scope.orderState.order.isDropAndNotD2h) {
               $scope.toggleSelectedShippingType(_shippingTypes.drop)
            } else if ($scope.orderState.order.isD2h) {
               _d2hState.memStoreOfSelectedAddress = $scope.orderState.order.dropToHomeDeliveryAddress
               $scope.toggleSelectedShippingType(_shippingTypes.d2h)
            } else if ($scope.orderState.order.isParcelCarrier) {
               $scope.toggleSelectedShippingType(_shippingTypes.parcel)
            } else {
               // Default the selected shipping type to Drop
               $scope.toggleSelectedShippingType(_shippingTypes.drop)
            }
         }
      }

      //================================================================================
      // Watchers / Outside-scoped callbacks
      //================================================================================

      //
      // Whenever the order changes
      //

      $scope.$watch('orderState.order', function () {
         // Prevent watcher from firing on init
         if (_isInitializing) {
            return
         }

         if ($scope.addresses && $scope.addresses.length) {
            return $q.all([
               // Refresh D2H estimates.
               fetchD2hEstimates(),
               // Refresh parcel carrier estimates for the relevant address.
               fetchParcelCarrierEstimates(
                  $scope.orderState.order.parcelCarrierDeliveryAddress || $scope.addresses[0],
                  true
               ),
            ])
         }
      })

      //
      // Whenever an address is added or a new one is created : PARCEL
      //

      function onParcelAddressEditOrNew(address, isNewAddress) {
         if ($scope.orderState) {
            // Refresh D2H estimates (in the background).
            fetchD2hEstimates()

            // If this is a new address, select it for delivery.
            if (isNewAddress) {
               // Note that this triggers the the estimates to be refetched (via the below `$scope.$watch('orderState.order', ...)` watcher)
               $scope.selectAddressForParcelCarrierDelivery(address)
            }
         }
      }

      $scope.afterParcelAddressEdit = function (address) {
         return onParcelAddressEditOrNew(address, false)
      }
      $scope.afterNewParcelAddressAdded = function (address) {
         return onParcelAddressEditOrNew(address, true)
      }

      //
      // Whenever an address is added or a new one is created : D2H
      //

      function onD2hAddressEditOrNew(address, isNewAddress) {
         if ($scope.addresses && $scope.addresses.length) {
            // Refresh parcel carrier estimates for the relevant address (in the background).
            fetchParcelCarrierEstimates(address, true)

            // Refresh D2H estimates.
            // TODO: [OPTIMIZATION] Only refetch estimates for the new/changed address (not all of them)
            return fetchD2hEstimates().then(function () {
               if (isNewAddress) {
                  $scope.selectAddressForD2h(address)
               }
            })
         }
      }

      $scope.afterD2hAddressEdit = function name(address) {
         return onD2hAddressEditOrNew(address, false)
      }
      $scope.afterD2hAddressAdded = function name(address) {
         return onD2hAddressEditOrNew(address, true)
      }

      //================================================================================
      // Initialization
      //================================================================================

      $scope.afterAddressesInitiallyLoaded = function () {
         if ($scope.addresses && $scope.addresses.length) {
            return $q.all([
               fetchD2hEstimates(),
               fetchParcelCarrierEstimates($scope.orderState.order.parcelCarrierDeliveryAddress || $scope.addresses[0]),
            ])
         } else {
            _d2hState.areEstimatesLoaded = true
            _parcelOptions.loading = false
         }
      }

      function init() {
         $q.all([maybeInitOrder(), initDrops()])
            .then(initSelectedShippingMethod)
            .finally(function () {
               // This timeout is to prevent the callback in the scope watcher on 'orderState.order' from firing prematurely.
               $timeout(function () {
                  _isInitializing = false
               })
            })
      }

      init()
   }
})(angular)
